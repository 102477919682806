const defaultTheme = {
  name: "default",
  bg: "#fafafa",
  tx1: "#000000",
  tx2: "#73737d",
  maxW: "1220px",
  gradient: `linear-gradient(180deg,rgba(217,219,224,0) 0%,#D9DBE0 100%)`,
  link: "#ff7c00",
  special: "#ff7c00",
  bg2: "#f3f3f3",
};

const invertTheme = {
  name: "invert",
  bg: "#111216",
  tx1: "#ffffff",
  tx2: "#73737d",
  maxW: "1220px",
  gradient: `linear-gradient(
    180deg,
    #111216 0%,
    rgba(66, 81, 98, 0.36) 100%
  )`,
  link: "#ff9f00",
  modalBg: "#131619",
  special: "#ff8f25",
  bg2: "#13161b",
};

export { invertTheme, defaultTheme };
