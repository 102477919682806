import React from "react";
import styled from "styled-components";
import { Nav, Footer } from "components";

export default function Layout({ children }) {
  return (
    <Container>
      <Nav />
      <div className="main">{children}</div>
      <Footer />
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  background: ${({ theme }) => theme.bg};
  transition: background 0.25s var(--ease-in-out-quad),
    color 0.25s var(--ease-in-out-quad);
  min-height: 100vh;
  a {
    color: ${({ theme }) => theme.link};
    font-weight: 600;
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${({ theme }) => theme.tx1};
    margin: 0;
  }

  .main {
    max-width: ${({ theme }) => theme.maxW};
    margin: 0 auto;
  }
  .bg-trans {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 590px;
    z-index: 0;
    pointer-events: none;
    background: ${({ theme }) => theme.gradient};
    transition: background 0.25s var(--ease-in-out-quad),
      color 0.25s var(--ease-in-out-quad);
  }
`;
