import React from "react";
import styled from "styled-components";

function Spinner({ height, background, size = "md" }) {
  return (
    <SpinnerContainer height={height} size={size} background={background}>
      <div className="lds-heart">
        <div></div>
      </div>
    </SpinnerContainer>
  );
}
function VideoSpinner({ height }) {
  return (
    <VideoSpinnerContainer height={height}>
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </VideoSpinnerContainer>
  );
}

export { VideoSpinner, Spinner };

const VideoSpinnerContainer = styled.div`
  height: ${({ height }) => `${height}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 70px;
    height: 70px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid ${({ theme }) => theme.tx1};
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
`;

function getHeartSize(size) {
  switch (size) {
    case "md":
      return `width: 80px;
              height: 80px;`;
    case "sm":
      return `width: 40px;
              height:40px`;
    default:
      return `width: 80px;
              height: 80px;`;
  }
}
function getHeartDivSize(size) {
  switch (size) {
    case "md":
      return `width: 32px;
              height: 32px;`;
    case "sm":
      return `width: 12px;
              height:12px`;
    default:
      return `width: 32px;
              height: 32px;`;
  }
}
function getHeartInnerSize(size, direction) {
  switch (size) {
    case "md":
      return `${direction}: -24px;`;
    case "sm":
      return `${direction}: -12px;`;
    default:
      return `top: -12px;`;
  }
}
const SpinnerContainer = styled.div`
  height: ${({ height }) => `${height}`};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.bg};
  .lds-heart {
    display: inline-block;
    position: relative;
    transform: rotate(45deg);
    transform-origin: 40px 40px;
    ${({ size }) => getHeartSize(size)}
  }
  .lds-heart div {
    top: 32px;
    left: 32px;
    position: absolute;
    background: ${({ theme }) => theme.tx1};
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
    ${({ size }) => getHeartDivSize(size)};
  }
  .lds-heart div:after,
  .lds-heart div:before {
    content: " ";
    position: absolute;
    display: block;
    ${({ size }) => getHeartDivSize(size)};
    background: ${({ theme }) => theme.tx1};
  }
  .lds-heart div:before {
    ${({ size }) => getHeartInnerSize(size, "left")};
    border-radius: 50% 0 0 50%;
  }
  .lds-heart div:after {
    ${({ size }) => getHeartInnerSize(size, "top")};
    border-radius: 50% 50% 0 0;
  }
`;
