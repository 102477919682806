import React from "react";
import styled from "styled-components";
import {
  LinkedInSvg,
  VimeoSvg,
  DocumentSvg,
  EmailSvg,
  PhoneSvg,
} from "components";
import resume from "assets/cheng_peng_resume.pdf";
import Content from "constants/Content";

export default function SocialLinks() {
  const { about } = Content;

  return (
    <Container className="link-area">
      <div className="social">
        <a
          href={about.vimeoUrl}
          target="_blank"
          className="social-link"
          rel="noreferrer"
        >
          <span>
            <VimeoSvg />
          </span>
        </a>
        <a
          href={about.linkedInUrl}
          target="_blank"
          className="social-link"
          rel="noreferrer"
        >
          <span>
            <LinkedInSvg />
          </span>
        </a>
        <a
          href={`mailto:${about.emailUrl}`}
          target="_blank"
          className="social-link"
          rel="noreferrer"
        >
          <span>
            <EmailSvg />
          </span>
        </a>
        <a
          href={`tel:${about.phone}`}
          target="_blank"
          className="social-link"
          rel="noreferrer"
        >
          <span>
            <PhoneSvg />
          </span>
        </a>
      </div>
      <a href={resume} target="_blank" className="resume-link" rel="noreferrer">
        <span>
          <DocumentSvg />
        </span>{" "}
        Resume
      </a>
    </Container>
  );
}

const Container = styled.div`
  border-top: 1px solid ${({ theme }) => theme.tx2};
  width: 80%;
  padding: 10px 0 0;
  margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    .social {
      margin: 30px 0 10px;
    }
  }
  .social-link span,
  .resume-link span {
    padding: 5px;
    display: inline-flex;
  }
  .social-link {
    svg {
      fill: ${({ theme }) => theme.tx2};
      transition: all 0.3s ease-in-out;
      height: 20px;
    }
    &:hover {
      svg {
        fill: ${({ theme }) => theme.tx1};
      }
    }
  }
  .resume-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.tx1};
    transition: all 0.3s ease-in-out;
    font-weight: 300;
    span {
      border-radius: 4px;
      transition: all 0.3s ease-in-out;
      color: ${({ theme }) => theme.tx1};
    }
    svg {
      fill: ${({ theme }) => theme.tx1};
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      color: ${({ theme }) => theme.tx2};
      transform: translateX(5px);
      span {
        background: ${({ theme }) => theme.tx1};
      }
      svg {
        fill: ${({ theme }) => theme.bg};
      }
    }
  }
`;
