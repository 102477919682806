import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { launchModal } from "redux/action/global";
import { Row, Col } from "antd";
import { useHistory } from "react-router";
export default function Card({ work, index }) {
  const { img, title, date, id } = work;
  const history = useHistory();
  const dispatch = useDispatch();
  const { global } = useSelector((state) => ({
    global: state.global,
  }));
  const { workView } = global;
  const openModal = () => {
    dispatch(launchModal({ id: "workModal", data: work }));
    history.push(`/work/${id}`);
  };

  return (
    <>
      <Container onClick={openModal} workView={workView}>
        <Row
          gutter={[24, 24]}
          style={{
            flexDirection: `${index % 2 === 0 ? "row-reverse" : "row"}`,
          }}
        >
          <Col span={workView === "grid" ? 24 : 12}>
            <img src={img} alt={title} />
          </Col>
          <Col span={workView === "grid" ? 24 : 12} className="content-col">
            <h4>{title}</h4>
            <p className="date">{date}</p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: ${({ workView }) => (workView === "grid" ? "column" : "row")};
  transition: all 0.33s ease-out;
  &:hover {
    transform: translateY(-10px);
    img {
      box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
        rgba(17, 17, 26, 0.05) 0px 8px 32px;
    }
    h4 {
      color: ${({ theme }) => theme.special};
    }
  }

  img {
    transition: all 0.33s ease-out;
    box-shadow: rgb(0 0 0 / 30%) 0px 30px 60px -10px,
      rgb(0 0 0 / 33%) 0px 18px 36px -18px;
    width: 100%;
    border-radius: 20px;
  }
  h4 {
    color: ${({ theme }) => theme.tx1};
    transition: all 0.3s ease-out;
    opacity: 0.8;
    letter-spacing: -1px;
    font-weight: 400;
  }
  .date {
    color: ${({ theme }) => theme.tx2};
    opacity: 0.3;
    font-weight: 500;
  }
  .content-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 578px) {
    h4 {
      font-size: ${({ workView }) => (workView === "list" ? "18px" : "1.5rem")};
    }
  }
`;
