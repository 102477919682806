import { actionTypes } from "redux/actionTypes";
import { invertTheme } from "constants/Theme";

const initialState = {
  theme: invertTheme,
  modal: { id: null, data: null },
  workView: "grid",
};

const types = actionTypes.global;

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_THEME:
      return { ...state, theme: action.payload.theme };
    case types.LAUNCH_MODAL:
      return {
        ...state,
        modal: {
          id: action.payload.id,
          data: action.payload.data,
        },
      };
    case types.CLOSE_MODAL:
      return {
        ...state,
        modal: { id: null, data: null },
      };

    case types.SET_WORK_VIEW:
      return {
        ...state,
        workView: action.payload.mode,
      };
    default:
      return state;
  }
};

export default globalReducer;
