import { Spinner } from "components";
import withTracker from "hook/withTracker";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

const Home = React.lazy(() => import("pages/Home"));
const About = React.lazy(() => import("pages/About"));

export default function Routes() {
  return (
    <Suspense fallback={<Spinner height={"100vh"} />}>
      <Router>
        <Switch>
          <Route path="/" exact component={withTracker(Home)} key="home" />
          <Route
            path="/work/:id"
            exact
            component={withTracker(Home)}
            key="work"
          />
          <Route
            path="/about"
            exact
            component={withTracker(About)}
            key="about"
          />
        </Switch>
      </Router>
    </Suspense>
  );
}
const links = [{ name: "About", path: "/about" }];
export { links };
