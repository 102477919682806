import React from "react";
import styled from "styled-components";
import { GridViewSvg, ListViewSvg } from "components";

function ViewButton({ mode, ...rest }) {
  switch (mode) {
    case "grid":
      return (
        <StyledViewButton {...rest}>
          <GridViewSvg />
        </StyledViewButton>
      );
    case "list":
      return (
        <StyledViewButton {...rest}>
          <ListViewSvg />
        </StyledViewButton>
      );
    default:
      return (
        <StyledViewButton {...rest}>
          <GridViewSvg />
        </StyledViewButton>
      );
  }
}
function ScrollBtn() {
  return <button className="button">Submit</button>;
}
export { ViewButton, ScrollBtn };
const StyledViewButton = styled.button`
  background: transparent;
  border: none;
  svg {
    transition: all 0.3s ease-in-out;
    fill: ${({ iconColor }) => iconColor};
    &:hover {
      transform: translateX(2px);
    }
  }
`;
