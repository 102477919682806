const HeartSvg = () => (
  <svg className="heart" viewBox="0 0 32 29.6">
    <path
      d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
    />
  </svg>
);
const DocumentSvg = () => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path d="M3 24h19v-23h-1v22h-18v1zm17-24h-18v22h18v-22zm-1 1h-16v20h16v-20zm-2 16h-12v1h12v-1zm0-3h-12v1h12v-1zm0-3h-12v1h12v-1zm-7.348-3.863l.948.3c-.145.529-.387.922-.725 1.178-.338.257-.767.385-1.287.385-.643 0-1.171-.22-1.585-.659-.414-.439-.621-1.04-.621-1.802 0-.806.208-1.432.624-1.878.416-.446.963-.669 1.642-.669.592 0 1.073.175 1.443.525.221.207.386.505.496.892l-.968.231c-.057-.251-.177-.449-.358-.594-.182-.146-.403-.218-.663-.218-.359 0-.65.129-.874.386-.223.258-.335.675-.335 1.252 0 .613.11 1.049.331 1.308.22.26.506.39.858.39.26 0 .484-.082.671-.248.187-.165.322-.425.403-.779zm3.023 1.78l-1.731-4.842h1.06l1.226 3.584 1.186-3.584h1.037l-1.734 4.842h-1.044z" />
  </svg>
);

const LinkedInSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
  </svg>
);

const VimeoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M22.875 10.063c-2.442 5.217-8.337 12.319-12.063 12.319-3.672 0-4.203-7.831-6.208-13.043-.987-2.565-1.624-1.976-3.474-.681l-1.128-1.455c2.698-2.372 5.398-5.127 7.057-5.28 1.868-.179 3.018 1.098 3.448 3.832.568 3.593 1.362 9.17 2.748 9.17 1.08 0 3.741-4.424 3.878-6.006.243-2.316-1.703-2.386-3.392-1.663 2.673-8.754 13.793-7.142 9.134 2.807z" />
  </svg>
);

const GridViewSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.33337 13.8424H12.0371V5.4165H4.33337V13.8424ZM4.33337 20.5832H12.0371V15.5276H4.33337V20.5832ZM13.963 20.5832H21.6667V12.1572H13.963V20.5832ZM13.963 5.4165V10.4721H21.6667V5.4165H13.963Z"></path>
  </svg>
);

const ListViewSvg = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.33331 15.1665H8.41174V10.8332H4.33331V15.1665ZM4.33331 20.5832H8.41174V16.2498H4.33331V20.5832ZM4.33331 9.74984H8.41174V5.4165H4.33331V9.74984ZM9.43135 15.1665H21.6666V10.8332H9.43135V15.1665ZM9.43135 20.5832H21.6666V16.2498H9.43135V20.5832ZM9.43135 5.4165V9.74984H21.6666V5.4165H9.43135Z"></path>
  </svg>
);
const EmailSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M24 0l-6 22-8.129-7.239 7.802-8.234-10.458 7.227-7.215-1.754 24-12zm-15 16.668v7.332l3.258-4.431-3.258-2.901z" />
  </svg>
);
const PhoneSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z" />
  </svg>
);

const CoffeeSvg = (props) => (
  <svg
    {...props}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M412.614,210.963h-28.927v-15.002c0-8.285-6.717-15.002-15.002-15.002H68.647c-8.285,0-15.002,6.717-15.002,15.002v95.159
        c0,24.231,3.978,48.846,11.501,71.184c7.938,23.565,22.331,44.026,41.853,59.686H38.642c-8.285-0.001-15.002,6.716-15.002,15.001
        c0,41.36,33.649,75.01,75.01,75.01h240.03c41.36,0,75.01-33.649,75.01-75.01c0-8.285-6.717-15.002-15.002-15.002h-68.357
        c10.812-8.674,20.042-18.824,27.478-30.158c54.862-2.021,96.761-26.267,121.458-70.463
        C506.435,272.746,469.35,210.963,412.614,210.963z M381.116,451.992c-6.191,17.463-22.876,30.004-42.435,30.004H98.65
        c-19.558,0-36.244-12.541-42.435-30.004C88.744,451.992,365.112,451.992,381.116,451.992z M353.682,291.119
        c0,21.013-3.434,42.316-9.932,61.606c-13.954,41.425-53.718,69.258-98.952,69.264h-52.268
        c-45.234-0.005-84.998-27.839-98.951-69.264c-6.498-19.289-9.932-40.592-9.932-61.606v-80.157h270.034V291.119z M453.075,306.731
        c-17.111,30.622-44.068,48.625-80.292,53.728c7.13-21.847,10.903-45.773,10.903-69.34v-50.153h28.927
        C446.437,240.965,469.065,278.118,453.075,306.731z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M152.726,89.493c14.238-9.492,22.738-25.561,22.737-42.986c0-17.425-8.5-34.494-22.738-43.986
        c-6.892-4.596-16.207-2.733-20.804,4.161c-4.596,6.894-2.732,16.208,4.161,20.804c5.959,3.973,9.376,11.542,9.376,19.021
        c0.002,7.481-3.415,14.049-9.375,18.022c-14.238,9.492-22.738,25.374-22.738,42.486c0,17.111,8.5,32.994,22.738,42.485
        c6.863,4.577,16.187,2.763,20.804-4.161c4.596-6.894,2.732-16.208-4.162-20.804c-5.959-3.973-9.377-10.359-9.377-17.521
        C143.349,99.852,146.766,93.467,152.726,89.493z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M227.735,89.493c14.238-9.492,22.738-25.561,22.737-42.986c0-17.425-8.5-34.494-22.738-43.986
        c-6.892-4.596-16.207-2.733-20.804,4.161c-4.596,6.894-2.732,16.208,4.161,20.804c5.959,3.973,9.376,11.542,9.376,19.021
        c0.002,7.481-3.415,14.049-9.375,18.022c-14.238,9.492-22.738,25.374-22.738,42.486c0,17.111,8.5,32.994,22.738,42.485
        c6.863,4.577,16.187,2.763,20.804-4.161c4.596-6.894,2.732-16.208-4.162-20.804c-5.959-3.973-9.377-10.359-9.377-17.521
        C218.358,99.852,221.776,93.467,227.735,89.493z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M302.746,89.493c14.238-9.492,22.738-25.561,22.738-42.986s-8.5-34.494-22.738-43.986
        c-6.894-4.596-16.208-2.733-20.804,4.161c-4.597,6.894-2.733,16.208,4.161,20.804c5.959,3.973,9.377,11.542,9.377,19.021
        c0,7.481-3.418,14.049-9.377,18.022c-14.238,9.492-22.738,25.374-22.738,42.486c0,17.111,8.5,32.994,22.738,42.485
        c6.882,4.588,16.2,2.745,20.804-4.161c4.597-6.894,2.733-16.208-4.161-20.804c-5.959-3.973-9.377-10.359-9.377-17.521
        C293.369,99.852,296.787,93.467,302.746,89.493z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

const LocationSvg = (props) => (
  <svg
    {...props}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M341.476,338.285c54.483-85.493,47.634-74.827,49.204-77.056C410.516,233.251,421,200.322,421,166
			C421,74.98,347.139,0,256,0C165.158,0,91,74.832,91,166c0,34.3,10.704,68.091,31.19,96.446l48.332,75.84
			C118.847,346.227,31,369.892,31,422c0,18.995,12.398,46.065,71.462,67.159C143.704,503.888,198.231,512,256,512
			c108.025,0,225-30.472,225-90C481,369.883,393.256,346.243,341.476,338.285z M147.249,245.945
			c-0.165-0.258-0.337-0.51-0.517-0.758C129.685,221.735,121,193.941,121,166c0-75.018,60.406-136,135-136
			c74.439,0,135,61.009,135,136c0,27.986-8.521,54.837-24.646,77.671c-1.445,1.906,6.094-9.806-110.354,172.918L147.249,245.945z
			 M256,482c-117.994,0-195-34.683-195-60c0-17.016,39.568-44.995,127.248-55.901l55.102,86.463
			c2.754,4.322,7.524,6.938,12.649,6.938s9.896-2.617,12.649-6.938l55.101-86.463C411.431,377.005,451,404.984,451,422
			C451,447.102,374.687,482,256,482z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M256,91c-41.355,0-75,33.645-75,75s33.645,75,75,75c41.355,0,75-33.645,75-75S297.355,91,256,91z M256,211
			c-24.813,0-45-20.187-45-45s20.187-45,45-45s45,20.187,45,45S280.813,211,256,211z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);
export {
  HeartSvg,
  ListViewSvg,
  GridViewSvg,
  LinkedInSvg,
  VimeoSvg,
  DocumentSvg,
  EmailSvg,
  PhoneSvg,
  CoffeeSvg,
  LocationSvg,
};
