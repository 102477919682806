import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { links } from "route";
import { Switch } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "redux/action/global";
import { Logo } from "components";

export default function Nav() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.global.theme);

  const toggle = () => {
    dispatch(toggleTheme());
  };
  return (
    <StyledContainer>
      <div className="main-nav">
        <Logo />
        <nav>
          {links.map((l, index) => (
            <Link to={l.path} key={index}>
              {l.name}
            </Link>
          ))}
          <Switch
            checkedChildren="Dark"
            unCheckedChildren="Light"
            defaultChecked={theme.name === "light" ? true : false}
            onClick={toggle}
          />
        </nav>
      </div>
    </StyledContainer>
  );
}
const StyledContainer = styled.div`
  max-width: ${({ theme }) => theme.maxW};
  margin: 0 auto;
  padding: 100px 20px 0 20px;
  @media (max-width: 578px) {
    padding: 50px 20px 0 20px;
  }

  .main-nav {
    display: flex;
    justify-content: space-between;
    @media (max-width: 578px) {
      flex-direction: column;
    }
    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      @media (max-width: 578px) {
        width: 100%;
      }
    }

    .ant-switch {
      background: #7b849f;
      .ant-switch-inner {
        color: #fff;
      }
    }
    .ant-switch-checked {
      background: #232326;
      .ant-switch-inner {
        color: #fff;
      }
    }
  }
`;
