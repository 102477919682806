import React from "react";
import { useSelector } from "react-redux";

export default function HeroBgSvg(props) {
  const theme = useSelector((state) => state.global.theme);
  const style = {
    bg: theme.name === "default" ? "#fafafa" : "#111216",
    el: theme.name === "default" ? "#f4f4f4" : "#161921",
  };
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="1015"
      height="747"
      viewBox="0 0 1015 747"
    >
      <g id="Group_11" data-name="Group 11" transform="translate(-1227 6807)">
        <rect
          id="Rectangle_8"
          data-name="Rectangle 8"
          width="1015"
          height="747"
          transform="translate(1227 -6807)"
          fill={style.bg}
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M.561,79.353A.5.5,0,0,1,0,78.792L.224,1.684a.4.4,0,0,1,.449-.449h21.55q10.326,0,16.331,6.285t6,17.06a28.71,28.71,0,0,1-3.143,13.805,23.292,23.292,0,0,1-8.193,8.979,20.428,20.428,0,0,1-11,3.143h-8.53V78.792a.5.5,0,0,1-.561.561ZM13.693,36.927h8.53A7.713,7.713,0,0,0,28.453,33.5,14.214,14.214,0,0,0,31.09,24.58,10.787,10.787,0,0,0,28.733,17.4a8.044,8.044,0,0,0-6.51-2.806l-8.53.112Zm57.13,43.549a20.816,20.816,0,0,1-11.168-3.087,22.621,22.621,0,0,1-7.913-8.25,23.08,23.08,0,0,1-2.918-11.561l.112-35.019A22.676,22.676,0,0,1,51.8,11.28a22.3,22.3,0,0,1,7.857-8.193A21.816,21.816,0,0,1,81.935,3.03,22.56,22.56,0,0,1,92.71,22.56v5.163a.4.4,0,0,1-.449.449H79.69a.4.4,0,0,1-.449-.449V22.56a9.268,9.268,0,0,0-2.413-6.4,7.733,7.733,0,0,0-6-2.694,7.342,7.342,0,0,0-6.173,2.75,10.061,10.061,0,0,0-2.133,6.342V57.579a9.465,9.465,0,0,0,2.413,6.734,7.759,7.759,0,0,0,5.893,2.582,7.544,7.544,0,0,0,6-2.862,9.748,9.748,0,0,0,2.413-6.454V52.416a.4.4,0,0,1,.449-.449H92.373a.4.4,0,0,1,.449.449v5.163a22.738,22.738,0,0,1-2.974,11.561,23.241,23.241,0,0,1-7.913,8.25A20.532,20.532,0,0,1,70.823,80.475Z"
          transform="translate(1450.83 -6684.401) rotate(45)"
          fill={style.el}
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M.561,79.353A.5.5,0,0,1,0,78.792L.224,1.684a.4.4,0,0,1,.449-.449h21.55q10.326,0,16.331,6.285t6,17.06a28.71,28.71,0,0,1-3.143,13.805,23.292,23.292,0,0,1-8.193,8.979,20.428,20.428,0,0,1-11,3.143h-8.53V78.792a.5.5,0,0,1-.561.561ZM13.693,36.927h8.53A7.713,7.713,0,0,0,28.453,33.5,14.214,14.214,0,0,0,31.09,24.58,10.787,10.787,0,0,0,28.733,17.4a8.044,8.044,0,0,0-6.51-2.806l-8.53.112Zm57.13,43.549a20.816,20.816,0,0,1-11.168-3.087,22.621,22.621,0,0,1-7.913-8.25,23.08,23.08,0,0,1-2.918-11.561l.112-35.019A22.676,22.676,0,0,1,51.8,11.28a22.3,22.3,0,0,1,7.857-8.193A21.816,21.816,0,0,1,81.935,3.03,22.56,22.56,0,0,1,92.71,22.56v5.163a.4.4,0,0,1-.449.449H79.69a.4.4,0,0,1-.449-.449V22.56a9.268,9.268,0,0,0-2.413-6.4,7.733,7.733,0,0,0-6-2.694,7.342,7.342,0,0,0-6.173,2.75,10.061,10.061,0,0,0-2.133,6.342V57.579a9.465,9.465,0,0,0,2.413,6.734,7.759,7.759,0,0,0,5.893,2.582,7.544,7.544,0,0,0,6-2.862,9.748,9.748,0,0,0,2.413-6.454V52.416a.4.4,0,0,1,.449-.449H92.373a.4.4,0,0,1,.449.449v5.163a22.738,22.738,0,0,1-2.974,11.561,23.241,23.241,0,0,1-7.913,8.25A20.532,20.532,0,0,1,70.823,80.475Z"
          transform="translate(1712.347 -6684.401) rotate(45)"
          fill={style.el}
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M.561,79.353A.5.5,0,0,1,0,78.792L.224,1.684a.4.4,0,0,1,.449-.449h21.55q10.326,0,16.331,6.285t6,17.06a28.71,28.71,0,0,1-3.143,13.805,23.292,23.292,0,0,1-8.193,8.979,20.428,20.428,0,0,1-11,3.143h-8.53V78.792a.5.5,0,0,1-.561.561ZM13.693,36.927h8.53A7.713,7.713,0,0,0,28.453,33.5,14.214,14.214,0,0,0,31.09,24.58,10.787,10.787,0,0,0,28.733,17.4a8.044,8.044,0,0,0-6.51-2.806l-8.53.112Zm57.13,43.549a20.816,20.816,0,0,1-11.168-3.087,22.621,22.621,0,0,1-7.913-8.25,23.08,23.08,0,0,1-2.918-11.561l.112-35.019A22.676,22.676,0,0,1,51.8,11.28a22.3,22.3,0,0,1,7.857-8.193A21.816,21.816,0,0,1,81.935,3.03,22.56,22.56,0,0,1,92.71,22.56v5.163a.4.4,0,0,1-.449.449H79.69a.4.4,0,0,1-.449-.449V22.56a9.268,9.268,0,0,0-2.413-6.4,7.733,7.733,0,0,0-6-2.694,7.342,7.342,0,0,0-6.173,2.75,10.061,10.061,0,0,0-2.133,6.342V57.579a9.465,9.465,0,0,0,2.413,6.734,7.759,7.759,0,0,0,5.893,2.582,7.544,7.544,0,0,0,6-2.862,9.748,9.748,0,0,0,2.413-6.454V52.416a.4.4,0,0,1,.449-.449H92.373a.4.4,0,0,1,.449.449v5.163a22.738,22.738,0,0,1-2.974,11.561,23.241,23.241,0,0,1-7.913,8.25A20.532,20.532,0,0,1,70.823,80.475Z"
          transform="translate(1668.096 -6493.595) rotate(45)"
          fill={style.el}
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M.561,79.353A.5.5,0,0,1,0,78.792L.224,1.684a.4.4,0,0,1,.449-.449h21.55q10.326,0,16.331,6.285t6,17.06a28.71,28.71,0,0,1-3.143,13.805,23.292,23.292,0,0,1-8.193,8.979,20.428,20.428,0,0,1-11,3.143h-8.53V78.792a.5.5,0,0,1-.561.561ZM13.693,36.927h8.53A7.713,7.713,0,0,0,28.453,33.5,14.214,14.214,0,0,0,31.09,24.58,10.787,10.787,0,0,0,28.733,17.4a8.044,8.044,0,0,0-6.51-2.806l-8.53.112Zm57.13,43.549a20.816,20.816,0,0,1-11.168-3.087,22.621,22.621,0,0,1-7.913-8.25,23.08,23.08,0,0,1-2.918-11.561l.112-35.019A22.676,22.676,0,0,1,51.8,11.28a22.3,22.3,0,0,1,7.857-8.193A21.816,21.816,0,0,1,81.935,3.03,22.56,22.56,0,0,1,92.71,22.56v5.163a.4.4,0,0,1-.449.449H79.69a.4.4,0,0,1-.449-.449V22.56a9.268,9.268,0,0,0-2.413-6.4,7.733,7.733,0,0,0-6-2.694,7.342,7.342,0,0,0-6.173,2.75,10.061,10.061,0,0,0-2.133,6.342V57.579a9.465,9.465,0,0,0,2.413,6.734,7.759,7.759,0,0,0,5.893,2.582,7.544,7.544,0,0,0,6-2.862,9.748,9.748,0,0,0,2.413-6.454V52.416a.4.4,0,0,1,.449-.449H92.373a.4.4,0,0,1,.449.449v5.163a22.738,22.738,0,0,1-2.974,11.561,23.241,23.241,0,0,1-7.913,8.25A20.532,20.532,0,0,1,70.823,80.475Z"
          transform="translate(1929.613 -6493.595) rotate(45)"
          fill={style.el}
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M.561,79.353A.5.5,0,0,1,0,78.792L.224,1.684a.4.4,0,0,1,.449-.449h21.55q10.326,0,16.331,6.285t6,17.06a28.71,28.71,0,0,1-3.143,13.805,23.292,23.292,0,0,1-8.193,8.979,20.428,20.428,0,0,1-11,3.143h-8.53V78.792a.5.5,0,0,1-.561.561ZM13.693,36.927h8.53A7.713,7.713,0,0,0,28.453,33.5,14.214,14.214,0,0,0,31.09,24.58,10.787,10.787,0,0,0,28.733,17.4a8.044,8.044,0,0,0-6.51-2.806l-8.53.112Zm57.13,43.549a20.816,20.816,0,0,1-11.168-3.087,22.621,22.621,0,0,1-7.913-8.25,23.08,23.08,0,0,1-2.918-11.561l.112-35.019A22.676,22.676,0,0,1,51.8,11.28a22.3,22.3,0,0,1,7.857-8.193A21.816,21.816,0,0,1,81.935,3.03,22.56,22.56,0,0,1,92.71,22.56v5.163a.4.4,0,0,1-.449.449H79.69a.4.4,0,0,1-.449-.449V22.56a9.268,9.268,0,0,0-2.413-6.4,7.733,7.733,0,0,0-6-2.694,7.342,7.342,0,0,0-6.173,2.75,10.061,10.061,0,0,0-2.133,6.342V57.579a9.465,9.465,0,0,0,2.413,6.734,7.759,7.759,0,0,0,5.893,2.582,7.544,7.544,0,0,0,6-2.862,9.748,9.748,0,0,0,2.413-6.454V52.416a.4.4,0,0,1,.449-.449H92.373a.4.4,0,0,1,.449.449v5.163a22.738,22.738,0,0,1-2.974,11.561,23.241,23.241,0,0,1-7.913,8.25A20.532,20.532,0,0,1,70.823,80.475Z"
          transform="translate(1798.855 -6302.788) rotate(45)"
          fill={style.el}
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M.561,79.353A.5.5,0,0,1,0,78.792L.224,1.684a.4.4,0,0,1,.449-.449h21.55q10.326,0,16.331,6.285t6,17.06a28.71,28.71,0,0,1-3.143,13.805,23.292,23.292,0,0,1-8.193,8.979,20.428,20.428,0,0,1-11,3.143h-8.53V78.792a.5.5,0,0,1-.561.561ZM13.693,36.927h8.53A7.713,7.713,0,0,0,28.453,33.5,14.214,14.214,0,0,0,31.09,24.58,10.787,10.787,0,0,0,28.733,17.4a8.044,8.044,0,0,0-6.51-2.806l-8.53.112Zm57.13,43.549a20.816,20.816,0,0,1-11.168-3.087,22.621,22.621,0,0,1-7.913-8.25,23.08,23.08,0,0,1-2.918-11.561l.112-35.019A22.676,22.676,0,0,1,51.8,11.28a22.3,22.3,0,0,1,7.857-8.193A21.816,21.816,0,0,1,81.935,3.03,22.56,22.56,0,0,1,92.71,22.56v5.163a.4.4,0,0,1-.449.449H79.69a.4.4,0,0,1-.449-.449V22.56a9.268,9.268,0,0,0-2.413-6.4,7.733,7.733,0,0,0-6-2.694,7.342,7.342,0,0,0-6.173,2.75,10.061,10.061,0,0,0-2.133,6.342V57.579a9.465,9.465,0,0,0,2.413,6.734,7.759,7.759,0,0,0,5.893,2.582,7.544,7.544,0,0,0,6-2.862,9.748,9.748,0,0,0,2.413-6.454V52.416a.4.4,0,0,1,.449-.449H92.373a.4.4,0,0,1,.449.449v5.163a22.738,22.738,0,0,1-2.974,11.561,23.241,23.241,0,0,1-7.913,8.25A20.532,20.532,0,0,1,70.823,80.475Z"
          transform="translate(2060.371 -6302.788) rotate(45)"
          fill={style.el}
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M.561,79.353A.5.5,0,0,1,0,78.792L.224,1.684a.4.4,0,0,1,.449-.449h21.55q10.326,0,16.331,6.285t6,17.06a28.71,28.71,0,0,1-3.143,13.805,23.292,23.292,0,0,1-8.193,8.979,20.428,20.428,0,0,1-11,3.143h-8.53V78.792a.5.5,0,0,1-.561.561ZM13.693,36.927h8.53A7.713,7.713,0,0,0,28.453,33.5,14.214,14.214,0,0,0,31.09,24.58,10.787,10.787,0,0,0,28.733,17.4a8.044,8.044,0,0,0-6.51-2.806l-8.53.112Zm57.13,43.549a20.816,20.816,0,0,1-11.168-3.087,22.621,22.621,0,0,1-7.913-8.25,23.08,23.08,0,0,1-2.918-11.561l.112-35.019A22.676,22.676,0,0,1,51.8,11.28a22.3,22.3,0,0,1,7.857-8.193A21.816,21.816,0,0,1,81.935,3.03,22.56,22.56,0,0,1,92.71,22.56v5.163a.4.4,0,0,1-.449.449H79.69a.4.4,0,0,1-.449-.449V22.56a9.268,9.268,0,0,0-2.413-6.4,7.733,7.733,0,0,0-6-2.694,7.342,7.342,0,0,0-6.173,2.75,10.061,10.061,0,0,0-2.133,6.342V57.579a9.465,9.465,0,0,0,2.413,6.734,7.759,7.759,0,0,0,5.893,2.582,7.544,7.544,0,0,0,6-2.862,9.748,9.748,0,0,0,2.413-6.454V52.416a.4.4,0,0,1,.449-.449H92.373a.4.4,0,0,1,.449.449v5.163a22.738,22.738,0,0,1-2.974,11.561,23.241,23.241,0,0,1-7.913,8.25A20.532,20.532,0,0,1,70.823,80.475Z"
          transform="translate(1537.338 -6302.788) rotate(45)"
          fill={style.el}
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M.561,79.353A.5.5,0,0,1,0,78.792L.224,1.684a.4.4,0,0,1,.449-.449h21.55q10.326,0,16.331,6.285t6,17.06a28.71,28.71,0,0,1-3.143,13.805,23.292,23.292,0,0,1-8.193,8.979,20.428,20.428,0,0,1-11,3.143h-8.53V78.792a.5.5,0,0,1-.561.561ZM13.693,36.927h8.53A7.713,7.713,0,0,0,28.453,33.5,14.214,14.214,0,0,0,31.09,24.58,10.787,10.787,0,0,0,28.733,17.4a8.044,8.044,0,0,0-6.51-2.806l-8.53.112Zm57.13,43.549a20.816,20.816,0,0,1-11.168-3.087,22.621,22.621,0,0,1-7.913-8.25,23.08,23.08,0,0,1-2.918-11.561l.112-35.019A22.676,22.676,0,0,1,51.8,11.28a22.3,22.3,0,0,1,7.857-8.193A21.816,21.816,0,0,1,81.935,3.03,22.56,22.56,0,0,1,92.71,22.56v5.163a.4.4,0,0,1-.449.449H79.69a.4.4,0,0,1-.449-.449V22.56a9.268,9.268,0,0,0-2.413-6.4,7.733,7.733,0,0,0-6-2.694,7.342,7.342,0,0,0-6.173,2.75,10.061,10.061,0,0,0-2.133,6.342V57.579a9.465,9.465,0,0,0,2.413,6.734,7.759,7.759,0,0,0,5.893,2.582,7.544,7.544,0,0,0,6-2.862,9.748,9.748,0,0,0,2.413-6.454V52.416a.4.4,0,0,1,.449-.449H92.373a.4.4,0,0,1,.449.449v5.163a22.738,22.738,0,0,1-2.974,11.561,23.241,23.241,0,0,1-7.913,8.25A20.532,20.532,0,0,1,70.823,80.475Z"
          transform="translate(1406.579 -6493.595) rotate(45)"
          fill={style.el}
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M.561,79.353A.5.5,0,0,1,0,78.792L.224,1.684a.4.4,0,0,1,.449-.449h21.55q10.326,0,16.331,6.285t6,17.06a28.71,28.71,0,0,1-3.143,13.805,23.292,23.292,0,0,1-8.193,8.979,20.428,20.428,0,0,1-11,3.143h-8.53V78.792a.5.5,0,0,1-.561.561ZM13.693,36.927h8.53A7.713,7.713,0,0,0,28.453,33.5,14.214,14.214,0,0,0,31.09,24.58,10.787,10.787,0,0,0,28.733,17.4a8.044,8.044,0,0,0-6.51-2.806l-8.53.112Zm57.13,43.549a20.816,20.816,0,0,1-11.168-3.087,22.621,22.621,0,0,1-7.913-8.25,23.08,23.08,0,0,1-2.918-11.561l.112-35.019A22.676,22.676,0,0,1,51.8,11.28a22.3,22.3,0,0,1,7.857-8.193A21.816,21.816,0,0,1,81.935,3.03,22.56,22.56,0,0,1,92.71,22.56v5.163a.4.4,0,0,1-.449.449H79.69a.4.4,0,0,1-.449-.449V22.56a9.268,9.268,0,0,0-2.413-6.4,7.733,7.733,0,0,0-6-2.694,7.342,7.342,0,0,0-6.173,2.75,10.061,10.061,0,0,0-2.133,6.342V57.579a9.465,9.465,0,0,0,2.413,6.734,7.759,7.759,0,0,0,5.893,2.582,7.544,7.544,0,0,0,6-2.862,9.748,9.748,0,0,0,2.413-6.454V52.416a.4.4,0,0,1,.449-.449H92.373a.4.4,0,0,1,.449.449v5.163a22.738,22.738,0,0,1-2.974,11.561,23.241,23.241,0,0,1-7.913,8.25A20.532,20.532,0,0,1,70.823,80.475Z"
          transform="translate(1973.864 -6684.401) rotate(45)"
          fill={style.el}
        />
      </g>
    </svg>
  );
}
