const actionTypes = {};

actionTypes.global = {
  TOGGLE_THEME: "TOGGLE_THEME",
  LAUNCH_MODAL: "LAUNCH_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  SET_WORK_VIEW: "SET_WORK_VIEW",
};

export { actionTypes };
