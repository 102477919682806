import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { links } from "route";
import {
  HeartSvg,
  Logo,
  SocialLinks,
  Glassmorphism,
  CoffeeSvg,
} from "components";

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <Container>
      <Glassmorphism />
      <div className="footer-content">
        <Logo />
        <footer>
          <nav>
            {links.map((l, index) => (
              <Link to={l.path} key={index}>
                {l.name}
              </Link>
            ))}
          </nav>
          <SocialLinks />
          <div className="signature">
            <p>© {year} Cheng Peng- PC</p>
            Made with <HeartSvg /> and{" "}
            <span>
              <CoffeeSvg className="coffee" />
            </span>
          </div>
        </footer>
      </div>
    </Container>
  );
}

const Container = styled.section`
  height: 400px;
  max-width: ${({ theme }) => theme.maxW};

  margin: 0 auto;
  text-align: center;
  position: relative;
  .footer-content {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
  }
  footer {
    text-align: center;
    font-size: 14px;
    color: ${({ theme }) => theme.tx2};
  }
  nav {
    margin-bottom: 20px;
  }
  span {
    margin: 0 5px;
  }
  .heart {
    fill: ${({ theme }) => theme.tx1};
    position: relative;
    top: 5px;
    width: 15px;
    animation: lds-heart 1s ease infinite;
    margin: 0 5px 5px 5px;
  }
  .coffee {
    width: 15px;
    fill: ${({ theme }) => theme.tx1};
  }
  .signature {
    p {
      margin-bottom: 10px;
      font-size: 14px;
    }
    margin: 0;
  }
`;
