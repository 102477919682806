import React from "react";
import styled from "styled-components";

function Glassmorphism() {
  return (
    <Container className="area">
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  height: 400px;
  .circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: ${({ theme }) =>
      theme.name === "invert"
        ? "rgba(255, 255, 255, 0.1)"
        : "rgb(255 153 0 / 10%)"};
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 80px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    animation: animate 25s linear infinite;
    bottom: -150px;
  }

  .circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
  }

  .circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
  }

  .circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
  }

  .circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
  }

  .circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
  }

  .circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
  }

  .circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
  }

  .circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
  }

  .circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
  }

  .circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
  }
`;
export { Glassmorphism };
// const ParallaxHeading = () => {
//   const texts = `hello`;
//   return (
//     <ParallaxHeadingContainer>
//       <div className={"barTop"} />
//       <h1 className={`copy h1`}>
//         {texts.split("").map((letter, i) => (
//           <Parallax key={`copy-${i}`} x={[0, 100 * (i - 1)]} className="letter">
//             {letter}
//           </Parallax>
//         ))}
//       </h1>
//       <div className="barBottom" />
//     </ParallaxHeadingContainer>
//   );
// };

// const Marquee = () => {
//   return (
//     <MarqueeContainer>
//       <Divider className="divider" />
//       <div className="container">
//         <BoxBg className="boxBg" />
//         <Parallax
//           className="text"
//           // offsetYMax={70}
//           // offsetYMin={-70}
//           y={[70, -70]}
//           // offsetXMax={-30}
//           // offsetXMin={30}
//           x={[30, -30]}
//         >
//           <span className="h1">Horizontal</span>
//         </Parallax>
//         <Parallax className="boxOutline" y={[35, -35]}>
//           <BoxOutline />
//         </Parallax>
//       </div>
//     </MarqueeContainer>
//   );
// };
// export { ParallaxHeading, Marquee };

// const MarqueeContainer = styled.div``;

// const ParallaxHeadingContainer = styled.div`
//   display: flex;
//   flex-flow: column wrap;
//   align-items: space-around;
//   justify-content: center;
//   align-items: center;
//   height: 50vh;

//   .copy {
//     margin: 0.2em 0;
//     text-align: center;
//   }

//   .barTop {
//     margin-left: 0.8em;
//     width: 20em;
//     height: 1.5em;
//     border-top: 0.45em solid purple;
//     border-bottom: 0.45em solid purple;
//     transform: skew(-10deg);
//   }
//   .barBottom {
//     composes: barTop;
//     margin-left: 0;
//     margin-right: 0.8em;
//   }

//   .letter {
//     display: inline-block;
//   }
// `;
