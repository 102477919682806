import React from "react";
import styled from "styled-components";
import Content from "constants/Content";
import { Card, ViewButton } from "components";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import { setWorkView } from "redux/action/global";
export default function WorkList() {
  const { works } = Content;
  const dispatch = useDispatch();
  const { global } = useSelector((state) => ({
    global: state.global,
  }));
  const { theme, workView } = global;

  const onViewBtnClick = (mode) => {
    dispatch(setWorkView(mode));
  };

  return (
    <Container>
      <div className="btn-area">
      <h3>Proudly Produced</h3>
        <ViewButton
          mode="grid"
          onClick={() => onViewBtnClick("grid")}
          iconColor={workView === "grid" ? theme.tx1 : theme.tx2}
        />
        <ViewButton
          mode="list"
          onClick={() => onViewBtnClick("list")}
          iconColor={workView === "list" ? theme.tx1 : theme.tx2}
        />
      </div>
      <Row
        gutter={workView === "grid" ? [24, 24] : [48, 48]}
        justify="space-around"
      >
        {works.map((w, index) => (
          <Col xs={24} md={workView === "grid" ? 12 : 24} key={index}>
            <Card work={w} index={index} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

const Container = styled.div`
  .btn-area {
    margin: 0 0 20px 0;
    text-align: right;
  }
`;
