import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
export default function BubbleChart() {
  const lists = [
    {
      title: "LA Base",
      weight: 5,
    },
    {
      title: "Location Manager",
      weight: 3,
    },
    {
      title: "Adobe Suite",
      weight: 3,
    },
    {
      title: "Busan Film Festival",
      weight: 5,
    },
    {
      title: "Snow Boarding",
      weight: 2,
    },
    {
      title: "Creative",
      weight: 4,
    },
    {
      title: "Commercial",
      weight: 4,
    },
    {
      title: "DGA '18 Jury Award",
      weight: 5,
    },
    {
      title: "Producer",
      weight: 5,
    },
    {
      title: "Client Communication",
      weight: 5,
    },
    {
      title: "Tech",
      weight: 4,
    },
    {
      title: "Graphic Design",
      weight: 3,
    },
    {
      title: "Detail Driven",
      weight: 5,
    },
    {
      title: "Editing",
      weight: 3,
    },
    {
      title: "Art",
      weight: 3,
    },
    {
      title: "Plant Daddy",
      weight: 5,
    },
    {
      title: "Travel",
      weight: 2,
    },
    {
      title: "3D Design",
      weight: 4,
    },
    {
      title: "PhotoShop",
      weight: 5,
    },
    {
      title: "Budget Sensitive",
      weight: 5,
    },
    {
      title: "Market Driven",
      weight: 6,
    },
    {
      title: "Telly Award",
      weight: 5,
    },

    {
      title: "Illustrator",
      weight: 2,
    },
  ];
  const marginStyles = [
    { marginBottom: "40px" },
    { marginBottom: "60px" },
    { marginTop: "50px" },
    { marginTop: "30px" },
    { marginRight: "10px" },
    { marginLeft: "10px" },
  ];
  return (
    <Container>
      <Row justify="center">
        {lists.map((l, index) => (
          <Col xs={12} md={l.weight} key={`bubble-chart-${index}`}>
            <div
              className={`bubble weight-${l.weight}`}
              style={marginStyles[getRandomInt(0, marginStyles.length)]}
            >
              {l.title}
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

const Container = styled.div`
  width: 1000px;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: unset;
  }
  .bubble {
    background-color: ${({ theme }) => theme.bg2};
    color: ${({ theme }) => theme.tx2};
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.33s cubic-bezier(0.39, 0.575, 0.565, 1);
    &:hover {
      background-color: ${({ theme }) => theme.tx1};
      color: ${({ theme }) => theme.bg};
    }
    &.weight-2 {
      height: 100px;
      width: 100px;
      font-size: 14px;
    }
    &.weight-3 {
      height: 120px;
      width: 120px;
      font-size: 16px;
    }
    &.weight-4 {
      height: 140px;
      width: 140px;
      font-size: 18px;
    }
    &.weight-5 {
      height: 160px;
      width: 160px;
      font-size: 20px;
    }
    &.weight-6 {
      height: 180px;
      width: 180px;
      font-size: 22px;
    }
  }
`;
