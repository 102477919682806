import React from "react";
import { ThemeProvider } from "components";
import Routes from "route";
import { Provider } from "react-redux";
import store from "redux/store";
import { ParallaxProvider } from "react-scroll-parallax";

function App() {
  return (
    <Provider store={store}>
      <ParallaxProvider>
        <ThemeProvider>
          <Routes />
        </ThemeProvider>
      </ParallaxProvider>
    </Provider>
  );
}

export default App;
