import { actionTypes } from "redux/actionTypes";
import { invertTheme, defaultTheme } from "constants/Theme";
const types = actionTypes.global;

export const toggleTheme = () => (dispatch, state) => {
  let theme =
    state().global.theme.name === "invert" ? defaultTheme : invertTheme;
  dispatch({
    type: types.TOGGLE_THEME,
    payload: { theme },
  });
};

export const launchModal =
  ({ id, data }) =>
  (dispatch) => {
    dispatch({
      type: types.LAUNCH_MODAL,
      payload: {
        id,
        data,
      },
    });
  };

export const closeModal = () => (dispatch) => {
  dispatch({
    type: types.CLOSE_MODAL,
  });
};

export const setWorkView = (mode) => (dispatch) => {
  dispatch({
    type: types.SET_WORK_VIEW,
    payload: { mode },
  });
};
