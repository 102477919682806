import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider as Provider } from "styled-components";

export default function ThemeProvider(props) {
  const { theme } = useSelector((state) => ({
    theme: state.global.theme,
  }));
  return <Provider {...props} theme={theme} />;
}
