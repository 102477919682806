import { Modal } from "antd";
import { Video } from "components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { closeModal } from "redux/action/global";
import styled from "styled-components";

export default function WorkModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const modal = useSelector((state) => state.global.modal);
  const work = modal.data;
  const handleOk = () => {
    history && history.push("/");
    dispatch(closeModal());
  };
  if (!work) {
    return null;
  } else {
    const { title, client, videoUrl } = work;
    return (
      <StyledModal
        visible={modal.id}
        footer={false}
        onOk={handleOk}
        onCancel={handleOk}
      >
        <h2>{title}</h2>
        <p className="date">{client}</p>
        <Video url={videoUrl} />
        {/* <article className="content">
          <section>
            <h5>Production Memory</h5>
            <p>{desc}</p>
          </section>
        </article> */}
      </StyledModal>
    );
  }
}

const StyledModal = styled(Modal)`
  &&& {
    width: 100vw !important;
    max-width: unset;
    height: calc(100vh - 100px);
    padding: 0;

    .ant-modal-content {
      border-radius: 20px 20px 0 0;
      background: ${({ theme }) => theme.modalBg};
      height: 100%;
    }
    .ant-modal-close {
      color: ${({ theme }) => theme.tx1};
    }
    .ant-modal-body {
      height: 100%;
      padding: 100px 50px;
      overflow: scroll;
    }
    h2 {
      text-align: center;
      color: ${({ theme }) => theme.tx1};
      width: 70%;
      margin: 0 auto 50px;
    }
    .video {
      text-align: center;
    }
    .date {
      color: ${({ theme }) => theme.tx2};
      opacity: 0.6;
      text-align: center;
    }
    article {
      max-width: 800px;
      margin: 50px auto;
      font-size: 20px;
      color: ${({ theme }) => theme.tx2};
    }
    h5 {
      color: ${({ theme }) => theme.tx1};
      font-size: 22px;
    }
    @media (max-width: 578px) {
      .ant-modal-body {
        padding: 50px 20px;
      }
      h2 {
        text-align: center;
        color: ${({ theme }) => theme.tx1};

        margin: 0 auto;
      }
    }
  }
`;
