import img1 from "assets/works/1.png";
import img10 from "assets/works/10.png";
import img11 from "assets/works/11.png";
import img12 from "assets/works/12.png";
import img13 from "assets/works/13.png";
import img14 from "assets/works/14.png";
import img15 from "assets/works/15.png";
import img16 from "assets/works/16.png";
import img17 from "assets/works/17.png";
import img2 from "assets/works/2.png";
import img22 from "assets/works/22.png";
import img3 from "assets/works/3.png";
import img5 from "assets/works/5.png";
import img6 from "assets/works/6.png";
import img7 from "assets/works/7.png";
import img8 from "assets/works/8.png";

const Content = {};

function calcExperienceYears(startYear) {
  const today = new Date();
  return today.getFullYear() - startYear;
}

Content.hero = {
  headline: (
    <>
      <h1>
        In case I don't see you, good afternoon, good evening, and good night.
      </h1>
      <div>
        <i>- The Truman Show</i>
      </div>
    </>
  ),
  intro: `A commercial producer with a creative art background and ${calcExperienceYears(
    2015
  )} years of experience in bridging clients’ goals and large-scale productions with detail-oriented budget management proficiency`,
};
Content.about = {
  title: "About PC",
  vimeoUrl: "https://vimeo.com/user143460590",
  linkedInUrl: "https://www.linkedin.com/in/cheng-peng-160144a5/",
  emailUrl: "pcpeng.prod@gmail.com",
  phone: "818-858-8995",
};
Content.works = [
  {
    id: 1,
    title: `vivo NEX 3 Commercial Official Video`,
    img: img7,
    desc: "NO MEMORIES LEFT. Not really. Too many memories and I have no idea where to start. I felt my brain was too busy to form memories for that 3 weeks. Worked with the top-notch crew, I mean seriously, I love them. Asking myself, what did I do? Emm, adjusted creative with clients at night because of time zone, updated new info with the production team by day. Watching the growing numbers on the cost report. Constantly worried about losing receipts or POs. Haha. Locked location and fell through. Locked casts and clients decided to change them (after booked). And delt with parking lots owner wasn’t the most smooth thing, and they speak Spanish :). There are so many stories I can talk about. Oh yes, this was the last project I worked with my EX-girlfriend. We had a wonderful trip to Napa and broke up six months later. Pop Quiz, do you recognize those LA iconic Location?",
    videoUrl: "https://vimeo.com/566213307",
    span: 14,
    client: "vivo China",
  },
  {
    id: 2,
    title: "Vivo NEX 3 Commercial-Introduction",
    img: img16,
    desc: `Check out the deck if possible. We delivered everything.`,
    videoUrl: "https://vimeo.com/566183216",
    span: 10,
    client: "vivo China",
  },
  {
    id: 3,
    title: "vivo NEX 3  Commercial Camera Features Official Video",
    img: img8,
    desc: "The girl was really cute and ate a lot of gummy bear",
    videoUrl: "https://vimeo.com/566182671",
    span: 10,
    client: "vivo China",
  },
  {
    id: 4,
    title: "vivo NEX  Commercial Camera Features Official Video",
    img: img11,
    desc: "This is a secret I think. But I am not afraid to share. I found another company borrowed our creative. It’s a compliment in a way, isn’t it?",
    videoUrl: "https://vimeo.com/566183077",
    span: 14,
    client: "vivo Global Release",
  },
  {
    id: 5,
    title: `vivo X LANY V20 Series Be The Focus`,
    img: img10,
    desc: `First time adopting virtual production. Start to listen to L.A.N.Y.’s song now. It’s quite good actually. And I was quite surprised about how small the world is. We studied a reference music video and amazed by the work. It turned out my go-to production designer later worked with that production company. She briefed their workflow for the reference music video they shot and guess what, I respect that production company even more.`,
    videoUrl: "https://vimeo.com/566182985",
    span: 14,
    client: "vivo Indonesia",
  },
  {
    id: 6,
    title: `Vivo V11 Pro Starry Black`,
    img: img13,
    desc: "I have to say, living in DTLA does give me a lot of privileges to do the pitching to clients. I am so familiar with the iconic location even though on a low-key side. Afgan is a well-known singer from Indonesia. He is the brand ambassador for VIVO Indonesia. His agent is really a detailed oriented guy. I have to say I really enjoy working with people when they know what they are doing. From shooting schedule to trip booking, his agent managed everything in detail. I bet Afgan won’t even ask him that many questions but I am sure he will have an answer to that.",
    videoUrl: "https://vimeo.com/566182556",
    span: 14,
    client: "vivo Indonesia",
  },
  {
    id: 7,
    title: `Vivo NEX 2 Commercial`,
    img: img17,
    desc: `Ok, the function point for this cell phone is the double screen. And it is what it is :).`,
    videoUrl: "https://vimeo.com/566183340",
    span: 14,
    client: "vivo China",
  },
  {
    id: 8,
    title: `vivo V11Pro Welcome to the Amazing`,
    img: img14,
    desc: `This commercial is for vivo India. Producing this project actually reminded me that we really need to understand our client and their culture. Cultural factors constantly got involved in all the production process i.e. casting or dialect. Also, hahahahahha, I live at the building right above where the cover photo was taken. Thought it would be the nearest location to me ever but turned out we have to company move to this location. What can I say, AD's the biggest.`,
    videoUrl: "https://vimeo.com/566182607",
    span: 14,
    client: "vivo India",
  },
  {
    id: 9,
    title: `vivo V15 Pro Experience True Performance powered by AI`,
    img: img15,
    desc: `Fun fact, I was being selected as one of the background actors. Unfortunately, in the establishing shot, I followed the director’s lead that “be happy, look to the other side and interact with lead talent on cue.” so, on cue, I looked at the lead talent and smiled. Then, DP laughed at me and saying “PC, I can see your teeth. Don’t look back anymore.” Period, that’s the BG lesson I learned that day.`,
    videoUrl: "https://vimeo.com/566182770",
    span: 14,
    client: "vivo India",
  },
  {
    id: 10,
    title: `Knives Out Mobile Game_Japanese Halloween Theme`,
    img: img2,
    desc: `OK, I honestly blame myself for picking up this only DTLA office location (THE only one I could find). No AC, superhot, sketlon crew. The loading dock was a million miles from the office. The building was like a maze. Deeply appreciate the Cam&GnE&Art team that helped me pull this off. The client liked it a lot saying "very cinematic"`,
    videoUrl: "https://vimeo.com/566182215",
    span: 14,
    client: "Knives Out Japan",
  },
  {
    id: 11,
    title: `Knives Out Mobile Game_Japanese Market_Firework Festival Theme`,
    img: img1,
    desc: `Emm, It was a great shoot. Shot in Glendale, During the pandemic time. Strictly followed the protocol and honestly had a tough time catching daylight. Oh yes, wardrobe left one set of costumes at home, and surprisingly I wasn't mad. :) Everything went well at the end of the day.`,
    videoUrl: "https://vimeo.com/566182301",
    span: 14,
    client: "Knives Out Japan",
  },
  {
    id: 12,
    title: `Knives Out Mobile Game_BaseballTheme`,
    img: img3,
    desc: `Rode the emotion roller coaster. The permit got stuck due to LA CITY PARK didn't get approval from the park. My line producer got rejected by the park manager without knowing why. 45 mins before the permit office close, I called the park manager and tried super hard to calm him down and knew the reason was about posted parking in a busy neighborhood. Updated the parking plan, resubmit the permit application. Happy Ending.`,
    videoUrl: "https://vimeo.com/566195252",
    span: 14,
    client: "Knives Out Japan",
  },
  {
    id: 13,
    title: `Knives Out Mobile Game_Valentines Day Theme Ending 2`,
    img: img5,
    desc: `It was my first time working with Chikako, a popular guest of the Japanese Netflix reality show Terrace's House. She was introduced by a friend I drink with. Haha. Since then, we have been working together for years. Love her as a person, super nice to work with. We shot this commercial while Black Mirror released its interactive try-to-be immersive new episode. We testify the idea with the double ending.`,
    videoUrl: "https://vimeo.com/566203363",
    span: 14,
    client: "Knives Out Japan",
  },
  {
    id: 14,
    title: `Knives Out Mobile Game_Valentines Day Theme Ending 1`,
    img: img6,
    desc: `It was my first time working with Chikako, a popular guest of the Japanese Netflix reality show Terrace's House. She was introduced by a friend I drink with. Haha. Since then, we have been working together for years. Love her as a person, super nice to work with. We shot this commercial while Black Mirror released its interactive try-to-be immersive new episode. We testify the idea with the double ending.`,
    videoUrl: "https://vimeo.com/566203494",
    span: 14,
    client: "Knives Out Japan",
  },
  {
    id: 15,
    title: `Knives Out Mobile Game_Japanese Market_Firework Festival Theme`,
    img: img12,
    desc: `Knives out, managed by Netease is one of my favorite clients. Thanks for their trust, I have been working with them for more than 3 years. And I am really thrilled to see the growth of our cast and crew. Yuto went LIVE on a digital billboard in the busiest intersection in Tokyo. Nobuaki played a supporting role in a feature with Tom Cruiz. Ante got into sundance with his amazing cinematography skill sets. I really had great time with them.`,
    videoUrl: "https://vimeo.com/566182458",
    span: 14,
    client: "Knives Out Japan",
  },
  {
    id: 16,
    title: "Three Kingdoms The Last Warlord ft.Jeremy Lin",
    desc: "Shot in SF during the pandemic period. Great clients! Super easy to work with! Creative got approved quickly. The conversation was very sufficient. All PPMs went smoothly as expected. Physical production wise, things pretty much went really well. At the beginning, locking down location was hard since the close down. And the schedule was really tight. Fun fact, LA crews were asked to leave for SF in a super short notice since CDC announced a lock down policy. We had to hit the road before the policy became effective.",
    videoUrl: "https://vimeo.com/567668024",
    img: img22,
    client: "Ling Xi",
  },
];
export default Content;
