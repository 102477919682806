import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Logo() {
  return (
    <Container>
      <Link to="/" className="logo">
        Cheng Peng <span> - PC</span>
      </Link>
    </Container>
  );
}

const Container = styled.div`
  .logo {
    font-family: "Staatliches", cursive;
    color: ${({ theme }) => theme.tx1};
    font-size: 28px;
    font-weight: 400;
  }
  span {
    display: inline-block;
    transition: all 0.4s ease-in-out;
    color: ${({ theme }) => theme.tx2};
    &:hover {
      opacity: 0.8;
      transform: translateX(5px);
    }
  }
`;
