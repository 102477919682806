import React from "react";
import { useSelector } from "react-redux";
import WorkModal from "./WorkModal";

export default function Modal() {
  const { modalId } = useSelector((state) => ({
    modalId: state.global.modal.id,
  }));

  switch (modalId) {
    case "workModal":
      return <WorkModal />;

    default:
      return null;
  }
}
