import { useEffect } from "react";
import ReactGA from "react-ga4";

ReactGA.initialize("G-QCXW1JJH7T");

export default function withTracker(WrappedComponent, options = {}) {
  const trackPage = (hitType, page) => {
    ReactGA.send({ hitType, page });
  };

  const HOC = (props) => {
    useEffect(
      () => trackPage({ hitType: "pageview", page: props.location.pathname }),
      [props.location.pathname]
    );

    return <WrappedComponent {...props} />;
  };

  return HOC;
}
