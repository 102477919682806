import ReactPlayer from "react-player";
import React, { useState } from "react";
import { VideoSpinner } from "components";
import styled from "styled-components";
import { Grid } from "antd";
export default function Video({ url }) {
  const [videoStatus, setVideoStatus] = useState("loading");

  const screens = Grid.useBreakpoint();
  const height = screens.xs ? 200 : 500;

  return (
    <Container>
      {videoStatus === "loading" && <VideoSpinner height={height} />}

      <div className={`video-wrapper ${videoStatus}`}>
        <ReactPlayer
          className="react-video"
          controls
          url={url}
          height={height}
          width="auto"
          onReady={() => setVideoStatus("ready")}
        />
      </div>
    </Container>
  );
}

const Container = styled.div`
  .video-wrapper {
    transition: opacity 0.8s ease-in-out;
    &.loading {
      opacity: 0;
    }
    &.ready {
      opacity: 1;
    }
  }
`;
